import { Avatar } from 'antd';
import React from 'react';

function ProfileView({ data }) {
    return (
        <div style={{ padding: 10, }}>
            <Avatar src={<img src={data?.avatar} alt={data?.name} />} size={100} />
            <h2 style={{ color: "white" }}>{data?.name}</h2>
        </div>
    );
}

export default ProfileView;