import React, { useEffect, useState } from 'react';
import CarouselView from '../components/CarouselView';
import { Button, Col, Row } from 'antd';
import { data } from '../components/data';
import Game from '../components/Game';
import { PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { useGlobalAudioPlayer } from 'react-use-audio-player';


function Home(props) {

    const [filteredData, setfilteredData] = useState([])
    const [playMP3, setplayMP3] = useState(true)

    const { load, play, pause } = useGlobalAudioPlayer();

    const playSound = () => {
        setplayMP3(!playMP3)
        if (playMP3) {
            play()
        } else {
            pause()
        }
    }

    useEffect(() => {
        load('/assets/audio/Shame_Bell.mp3', {
            autoplay: true,
            loop: true
        });
    }, [])


    return (
        <Row>
            <Col span={24} style={{ padding: 20, }}>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                    <Game data={data?.filter(a => !filteredData?.map(b => b?.id).includes(a.id))} filteredData={filteredData} setfilteredData={setfilteredData} />
                </div>
            </Col>
            <Col span={24} style={{ margin: 'auto' }}>
                <Row justify={'center'} gutter={10} style={{ maxWidth: 800, margin: 'auto' }}>
                    <Col span={20}>

                        <h1 style={{ color: "white" }}>{'შეაგროვე ყველა - ' + data?.filter(a => !filteredData?.map(b => b?.id).includes(a.id))?.length}</h1>
                    </Col>
                    <Col span={4}>
                        <Button icon={playMP3 ? <PlayCircleOutlined /> : <PauseCircleOutlined />} onClick={playSound} />
                    </Col>
                </Row>
            </Col>
            <Col span={24} style={{ padding: 10, }}>
                <CarouselView data={filteredData} />
            </Col>
        </Row>

    );
}

export default Home;