import React from 'react';
import { Avatar } from 'antd';

function ButtonProfile({ data, onClick }) {
    return (
        // <div style={{ cursor: 'pointer', borderRadius: '50%', overflow: "hidden", width: 50, height: 50 }}>
        <Avatar onClick={() => onClick(data)} src={<img src={data?.avatar} alt={data?.name} width={90} />} size={80} shape='circle' style={{ cursor: 'grab' }} />
        // </div>
    );
}

export default ButtonProfile;