import React, { useEffect, useState } from 'react';
import ButtonProfile from './ButtonProfile';
import { getRandomeIndex } from './actions/getRandome';
import { Space } from 'antd';
;

function Game({ data, filteredData, setfilteredData }) {

    const [randomElement, setrandomElement] = useState(getRandomeIndex(data))


    useEffect(() => {
        const divs = document.querySelectorAll("[id^='col']")
        const globalIterval = setInterval(() => {
            const randomeDivRef = Math.floor(Math.random() * 15)
            if (!!getRandomeIndex(data)) {
                setrandomElement(getRandomeIndex(data))
            }

            divs.forEach(d => {
                d.style.display = 'block'
                if (d.getAttribute('id') !== `col${randomeDivRef}`) {
                    d.style.display = 'none'
                }
                setTimeout(() => {
                    d.style.display = 'none'
                }, 1000);
            });

        }, 5000);
        return () => clearInterval(globalIterval);
    }, [])


    const clickHandler = (d) => {
        setfilteredData(pre => [...pre, d])
    }

    return (
        <div style={{ display: "flex", justifyContent: 'center', maxWidth: 800, maxHeight: 600, border: '1px solid white', padding: 20, borderRadius: 20 }} className="space-align-block">

            <Space size={[5, 5]} wrap align='center' style={{ justifyContent: 'center' }}>
                <div style={{ width: '20vw', height: 80, borderRadius: 20 }}>
                    <div style={{ width: '100%', display: 'none', justifyContent: 'center' }} id='col1'>
                        <ButtonProfile data={randomElement} onClick={clickHandler} />
                    </div>
                </div>
                <div style={{ width: '20vw', height: 80, borderRadius: 20 }}>
                    <div style={{ width: '100%', display: 'none', justifyContent: 'center' }} id='col2'>
                        <ButtonProfile data={randomElement} onClick={clickHandler} />
                    </div>
                </div>
                <div style={{ width: '20vw', height: 80, borderRadius: 20 }}>
                    <div style={{ width: '100%', display: 'none', justifyContent: 'center' }} id='col3'>
                        <ButtonProfile data={randomElement} onClick={clickHandler} />
                    </div>
                </div>
                <div style={{ width: '20vw', height: 80, borderRadius: 20 }}>
                    <div style={{ width: '100%', display: 'none', justifyContent: 'center' }} id='col4'>
                        <ButtonProfile data={randomElement} onClick={clickHandler} />
                    </div>
                </div>
                <div style={{ width: '20vw', height: 80, borderRadius: 20 }}>
                    <div style={{ width: '100%', display: 'none', justifyContent: 'center' }} id='col5'>
                        <ButtonProfile data={randomElement} onClick={clickHandler} />
                    </div>
                </div>
                <div style={{ width: '20vw', height: 80, borderRadius: 20 }}>
                    <div style={{ width: '100%', display: 'none', justifyContent: 'center' }} id='col6'>
                        <ButtonProfile data={randomElement} onClick={clickHandler} />
                    </div>
                </div>
                <div style={{ width: '20vw', height: 80, borderRadius: 20 }}>
                    <div style={{ width: '100%', display: 'none', justifyContent: 'center' }} id='col7'>
                        <ButtonProfile data={randomElement} onClick={clickHandler} />
                    </div>
                </div>
                <div style={{ width: '20vw', height: 80, borderRadius: 20 }}>
                    <div style={{ width: '100%', display: 'none', justifyContent: 'center' }} id='col8' >
                        <ButtonProfile data={randomElement} onClick={clickHandler} />
                    </div>
                </div>
                <div style={{ width: '20vw', height: 80, borderRadius: 20 }}>
                    <div style={{ width: '100%', display: 'none', justifyContent: 'center' }} id='col9'>
                        <ButtonProfile data={randomElement} onClick={clickHandler} />
                    </div>
                </div>
                <div style={{ width: '20vw', height: 80, borderRadius: 20 }}>
                    <div style={{ width: '100%', display: 'none', justifyContent: 'center' }} id='col10'>
                        <ButtonProfile data={randomElement} onClick={clickHandler} />
                    </div>
                </div>
                <div style={{ width: '20vw', height: 80, borderRadius: 20 }}>
                    <div style={{ width: '100%', display: 'none', justifyContent: 'center' }} id='col11'>
                        <ButtonProfile data={randomElement} onClick={clickHandler} />
                    </div>
                </div>
                <div style={{ width: '20vw', height: 80, borderRadius: 20 }}>
                    <div style={{ width: '100%', display: 'none', justifyContent: 'center' }} id='col12'>
                        <ButtonProfile data={randomElement} onClick={clickHandler} />
                    </div>
                </div>
                <div style={{ width: '20vw', height: 80, borderRadius: 20 }}>
                    <div style={{ width: '100%', display: 'none', justifyContent: 'center' }} id='col13'>
                        <ButtonProfile data={randomElement} onClick={clickHandler} />
                    </div>
                </div>
                <div style={{ width: '20vw', height: 80, borderRadius: 20 }}>
                    <div style={{ width: '100%', display: 'none', justifyContent: 'center' }} id='col14'>
                        <ButtonProfile data={randomElement} onClick={clickHandler} />
                    </div>
                </div>
                <div style={{ width: '20vw', height: 80, borderRadius: 20 }}>
                    <div style={{ width: '100%', display: 'none', justifyContent: 'center' }} id='col15'>
                        <ButtonProfile data={randomElement} onClick={clickHandler} />
                    </div>
                </div>
                <div style={{ width: '20vw', height: 80, borderRadius: 20 }}>
                    <div style={{ width: '100%', display: 'none', justifyContent: 'center' }} id='col0'>
                        <ButtonProfile data={randomElement} onClick={clickHandler} />
                    </div>
                </div>
            </Space>
        </div>
    );
}

export default Game;