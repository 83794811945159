export const data = [
    {
        id: 1,
        avatar: '/assets/img/barvenashvili.png',
        name: 'ბარვენაშვილი გიორგი'
    },
    {
        id: 2,
        avatar: '/assets/img/benashvili.jpg',
        name: 'ბენაშვილი გია'
    },
    {
        id: 3,
        avatar: '/assets/img/beraia.jpg',
        name: 'ბერაია ირაკლი (დაჩი)'
    },
    {
        id: 4,
        avatar: '/assets/img/beradze.jpg',
        name: 'ბერაძე რიმა'
    },
    {
        id: 5,
        avatar: '/assets/img/bitadze.jpg',
        name: 'ბითაძე მაია'
    },
    {
        id: 6,
        avatar: '/assets/img/bolqvadze.jpg',
        name: 'ბოლქვაძე ანზორ'
    },
    {
        id: 7,
        avatar: '/assets/img/ebolqvadze.jpg',
        name: 'ბოლქვაძე ელისო'
    },
    {
        id: 8,
        avatar: '/assets/img/bochorishvili.jpg',
        name: 'ბოჭორიშვილი მაკა'
    },
    {
        id: 9,
        avatar: '/assets/img/gociridze.jpg',
        name: 'გოცირიძე ელგუჯა'
    },
    {
        id: 10,
        avatar: '/assets/img/davituliani.jpg',
        name: 'დავითულაინი ბექა'
    },
    {
        id: 11,
        avatar: '/assets/img/dalaqishvili.jpg',
        name: 'ალექსანდრე დალაქიშვილი'
    },
    {
        id: 12,
        avatar: '/assets/img/dargali.jpg',
        name: 'ზაურ დარგალი'
    },
    {
        id: 13,
        avatar: '/assets/img/dasen.jpg',
        name: 'ისკო დასენი'
    },
    {
        id: 14,
        avatar: '/assets/img/dugladze.jpg',
        name: 'ზაალ დუგლაძე'
    },
    {
        id: 15,
        avatar: '/assets/img/enuqidze.jpg',
        name: 'ავთანდილ ენუქიძე'
    },
    {
        id: 16,
        avatar: '/assets/img/genuqidze.jpg',
        name: 'გოჩა ენუქიძე'
    },
    {
        id: 17,
        avatar: '/assets/img/volski.jpg',
        name: 'გია ვოლსკი'
    },
    {
        id: 18,
        avatar: '/assets/img/zavradashvili.jpg',
        name: 'ირმა ზავრადაშვილი'
    },
    {
        id: 19,
        avatar: '/assets/img/zarqua.jpg',
        name: 'ირაკლი ზარქუა'
    },
    {
        id: 20,
        avatar: '/assets/img/talakvadze.jpg',
        name: 'არჩილ თალაკვაძე'
    },
    {
        id: 21,
        avatar: '/assets/img/toloraia.jpg',
        name: 'ედიშერ თოლორაია'
    },
    {
        id: 22,
        avatar: '/assets/img/injia.jpg',
        name: 'ფრიდონ ინჯია'
    },
    {
        id: 23,
        avatar: '/assets/img/ionata.jpg',
        name: 'რატი იონათამიშვილი'
    },
    {
        id: 24,
        avatar: '/assets/img/kacharava.jpg',
        name: 'დავით კაჭარავა'
    },
    {
        id: 25,
        avatar: '/assets/img/kaxadze.jpg',
        name: 'ლადო კახაძე'
    },
    {
        id: 26,
        avatar: '/assets/img/kaxiani.jpg',
        name: 'გიორგი კახიანი'
    },
    {
        id: 27,
        avatar: '/assets/img/kaxishvili.jpg',
        name: 'კახა კახიშვილი'
    },
    {
        id: 28,
        avatar: '/assets/img/kvijinadze.jpg',
        name: 'პაატა კვიჟინაძე'
    },
    {
        id: 29,
        avatar: '/assets/img/kviciani.jpg',
        name: 'ბაია კვიციანი'
    },
    {
        id: 30,
        avatar: '/assets/img/kircxalia.jpg',
        name: 'ირაკლი კირცხალია'
    },
    {
        id: 31,
        avatar: '/assets/img/beradze.jpg',
        name: 'სუმბატ კიურეღიანი'
    },
    {
        id: 32,
        avatar: '/assets/img/kobiashvili.jpg',
        name: 'ლევან კობიაშვილი'
    },
    {
        id: 33,
        avatar: '/assets/img/koncelidze.jpg',
        name: 'რესან კონცელიძე'
    },
    {
        id: 34,
        avatar: '/assets/img/lashxi.jpg',
        name: 'მარიამ ლაშხია'
    },
    {
        id: 35,
        avatar: '/assets/img/lominadze.jpg',
        name: 'ზაზა ლომინაძე'
    },
    {
        id: 36,
        avatar: '/assets/img/matikashvili.jpg',
        name: 'დავით მათიკაშვილი'
    },
    {
        id: 37,
        avatar: '/assets/img/manukiani.jpg',
        name: 'სამველ მანუკიანი'
    },
    {
        id: 38,
        avatar: '/assets/img/machavariani.png',
        name: 'ლევან მაჭავარიანი'
    },
    {
        id: 39,
        avatar: '/assets/img/macharashvili.png',
        name: 'გურამ მაჭარაშვილი'
    },
    {
        id: 40,
        avatar: '/assets/img/mgaloblishvili.jpg',
        name: 'ლევან მგალობლიშვილი'
    },
    {
        id: 41,
        avatar: '/assets/img/mezurnishvili.jpg',
        name: 'ირაკლი მეზურნიშვილი'
    },
    {
        id: 42,
        avatar: '/assets/img/mishveliani.jpg',
        name: 'გოგი მეშველიანი'
    },
    {
        id: 43,
        avatar: '/assets/img/mezmariashvili.jpg',
        name: 'ირაკლი მეძმარიაშვილი'
    },
    {
        id: 44,
        avatar: '/assets/img/mirzoevi.jpg',
        name: 'სავალან მირზოევი'
    },
    {
        id: 45,
        avatar: '/assets/img/miqnadze.jpg',
        name: 'გივი მიქანაძე'
    },
    {
        id: 46,
        avatar: '/assets/img/miqeladze.jpg',
        name: 'ზაალ მიქელაძე'
    },
    {
        id: 47,
        avatar: '/assets/img/nikuradze.png',
        name: 'იმედა ნიკურაძე'
    },
    {
        id: 48,
        avatar: '/assets/img/obolashvili.jpg',
        name: 'ანტონ ობოლაშვილი'
    },
    {
        id: 49,
        avatar: '/assets/img/odisharia.jpg',
        name: 'ბექა ოდიშარია'
    },
    {
        id: 50,
        avatar: '/assets/img/oxanashvili.jpg',
        name: 'ანრი ოხანაშვილი'
    },
    {
        id: 51,
        avatar: '/assets/img/papuashvili.jpg',
        name: 'შალვა პაპუაშვილი'
    },
    {
        id: 52,
        avatar: '/assets/img/samxarauli.jpg',
        name: 'გელა სამხარაული'
    },
    {
        id: 53,
        avatar: '/assets/img/samxaradze.jpg',
        name: 'დიმიტრი სამხარაძე'
    },
    {
        id: 54,
        avatar: '/assets/img/sanikidze.jpg',
        name: 'ვიქტორ სანიკიძე'
    },
    {
        id: 55,
        avatar: '/assets/img/sefashvili.jpg',
        name: 'ეკა სეფაშვილი'
    },
    {
        id: 56,
        avatar: '/assets/img/songulashvili.jpg',
        name: 'დავით სონღულაშვილი'
    },
    {
        id: 57,
        avatar: '/assets/img/sosiashvili.jpg',
        name: 'გიორგი სოსიაშვილი'
    },
    {
        id: 58,
        avatar: '/assets/img/sozar.jpg',
        name: 'სოზარ სუბარი'
    },
    {
        id: 59,
        avatar: '/assets/img/tabatadze.jpg',
        name: 'ალექსანდრე ტაბატაძე'
    },
    {
        id: 60,
        avatar: '/assets/img/turzelaze.jpg',
        name: 'ნოდარ ტურძელაძე'
    },
    {
        id: 61,
        avatar: '/assets/img/qadagishvili.jpg',
        name: 'ირაკლი ქადაგიშვილი'
    },
    {
        id: 62,
        avatar: '/assets/img/qrumidze.jpg',
        name: 'ლევან ქარუმიძე'
    },
    {
        id: 63,
        avatar: '/assets/img/qvaraia.jpg',
        name: 'მერაბ ქვარაია'
    },
    {
        id: 64,
        avatar: '/assets/img/qurasbediani.png',
        name: 'სალომე ქურასბედიანი'
    },
    {
        id: 65,
        avatar: '/assets/img/gudushauri.jpg',
        name: 'ალუდა ღუდუშაური'
    },
    {
        id: 66,
        avatar: '/assets/img/kavelashvili.jpg',
        name: 'მიხეილ ყაველაშვილი'
    },
    {
        id: 67,
        avatar: '/assets/img/sharmazan.jpg',
        name: 'თენგიზ შარმანაშვილი'
    },
    {
        id: 68,
        avatar: '/assets/img/shataki.jpg',
        name: 'ირაკლი შატაკიშვილი'
    },
    {
        id: 69,
        avatar: '/assets/img/chakve.png',
        name: 'გიორგი ჩაკვეტაძე'
    },
    {
        id: 70,
        avatar: '/assets/img/chanqse.jpg',
        name: 'გოდერძი ჩანქსელიანი'
    },
    {
        id: 71,
        avatar: '/assets/img/charkviani.png',
        name: 'ქეთევან ჩარკვიანი'
    },
    {
        id: 72,
        avatar: '/assets/img/chigogidze.jpg',
        name: 'ვასილ ჩიგოგიძე'
    },
    {
        id: 73,
        avatar: '/assets/img/cagareishvili.jpg',
        name: 'გიორგი ცაგარეიშვილი'
    },
    {
        id: 74,
        avatar: '/assets/img/waqadze.jpg',
        name: 'ბეჟან წაქაძე'
    },
    {
        id: 75,
        avatar: '/assets/img/wilosani.jpg',
        name: 'ნინო წილოსანი'
    },
    {
        id: 76,
        avatar: '/assets/img/xwilosani.png',
        name: 'ხატია წილოსანი'
    },
    {
        id: 77,
        avatar: '/assets/img/greta.jpg',
        name: 'გრეტა წიწავა'
    },
    {
        id: 78,
        avatar: '/assets/img/xabareli.jpg',
        name: 'შოთა ხაბარელი'
    },
    {
        id: 79,
        avatar: '/assets/img/xaxubia.jpg',
        name: 'გიორგი ხახუბია'
    },
    {
        id: 80,
        avatar: '/assets/img/xundadze.png',
        name: 'დიმიტრი ხუნდაძე'
    },
    {
        id: 81,
        avatar: '/assets/img/jafaridze.jpg',
        name: 'ვიქტორ ჯაფარიძე'
    },
    {
        id: 82,
        avatar: '/assets/img/jinjolava.png',
        name: 'სალომე ჯინჯოლავა'
    },
    {
        id: 83,
        avatar: '/assets/img/wiwinadze.jpg',
        name: 'გივი ჭიჭინაძე'
    },
    {
        id: 84,
        avatar: '/assets/img/nsamxaradze.jpg',
        name: 'ნიკოლოზ სამხარაძე'
    },
]