import { Carousel } from 'antd';
import React, { useEffect } from 'react';

import ProfileView from './ProfileView';;


function CarouselView({ data }) {
    return (
        data?.length > 0 &&
        <Carousel infinite={true} arrows={true} autoplay={true} dots={false} dotPosition='bottom'>
            {
                data?.map(d => (
                    <ProfileView key={d.id} data={d} />
                ))
            }
        </Carousel>

    );
}

export default CarouselView;